<template>
  <div class="roleManager">
    <el-button type="success"
               icon="el-icon-plus"
               @click="addRole">添加</el-button>
    <el-button type="primary"
               @click="teacherRoleClick">教师授权</el-button>
    <el-table :data="tableData"
              style="width: 100%"
              stripe>
      <el-table-column label="序号"
                       width="50px"
                       align="center"
                       type="index">
      </el-table-column>
      <el-table-column label="角色名称"
                       align="center"
                       prop="name">
      </el-table-column>
      <!-- <el-table-column label="角色类型"
                       align="center"
                       prop="typeStr">
      </el-table-column> -->
      <el-table-column label="操作人"
                       align="center"
                       prop="managerName">
      </el-table-column>
      <el-table-column label="更新时间"
                       align="center"
                       prop="lastUpdateTime">
      </el-table-column>
      <el-table-column label="操作"
                       align="center"
                       width="380px">
        <template slot-scope="scope">
          <el-button type="success"
                     size="mini"
                     @click="authorityManage(scope.row.id, scope.row.name)">查看权限
          </el-button>
          <el-button size="mini"
                     @click="moduleManagement(scope.row.id, scope.row.name)">编辑权限
          </el-button>
          <el-button @click="modifyRole(scope.row)"
                     size="mini"
                     type="primary">修改
          </el-button>
          <el-button @click="deleteRole(scope.row.id)"
                     size="mini"
                     type="danger">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="roleForm.roleId ? '修改角色' : '添加角色'"
               :visible.sync="addRoleVisible"
               width="600px">
      <el-form :model="roleForm"
               ref="roleForm"
               label-width="100px"
               :rules="rules">
        <el-form-item label="角色名称："
                      prop="roleName">
          <el-input v-model="roleForm.roleName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="类型"
                      prop="roleType">
          <el-select v-model="roleForm.roleType"
                     placeholder="请选择类型"
                     filterable
                     value-key="type">
            <el-option v-for="role in roleTypeList"
                       :key="role.type"
                       :label="role.name"
                       :value="{ name: role.name, type: role.type }"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer">
        <el-button @click="addRoleVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addRoleConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="教师授权"
               :visible.sync="teacherRoleVisible"
               width="600px">
      <el-form :model="teacherRoleForm"
               ref="teacherRoleForm"
               class="teacherRoleForm"
               label-width="100px">
        <el-form-item label="角色"
                      required
                      prop="roleName">
          <el-select v-model="teacherRoleForm.roleId">
            <el-option v-for="roleType in roleTypeList"
                       :key="roleType.id"
                       :label="roleType.name"
                       :value="roleType.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份">
          <el-select v-model="teacherRoleForm.identity"
                     @change="identityChange">
            <el-option v-for="identity in identityList"
                       :key="identity.value"
                       :label="identity.name"
                       :value="identity.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="教师"
                      prop="roleName">
          <el-select placeholder="搜索教师名称"
                     filterable
                     multiple
                     @change="selectTeacher"
                     v-model="teacherRoleForm.teacherList">
            <el-option v-for="teacher in allTeacherList"
                       :key="teacher.id"
                       :label="teacher.name"
                       :value="teacher.id">
            </el-option>
          </el-select>
          <div>
            <el-checkbox :indeterminate="isIndeterminate"
                         v-model="checkAll"
                         @change="handleCheckAllChange">全选</el-checkbox>
            <el-checkbox-group v-model="teacherRoleForm.teacherList"
                               @change="teacherChange">
              <el-checkbox v-for="teacher in allTeacherList"
                           :label="teacher.id"
                           :key="teacher.id">{{teacher.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="teacherRoleVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addTeacherRoleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "roleManager",
  data() {
    return {
      tableData: [],
      addRoleVisible: false,
      roleForm: { roleName: "", roleId: "", roleType: null },
      rules: {
        roleName: [{ required: true }],
      },
      //   roleTypeList: [
      //     { name: "管理员", type: 1 },
      //     { name: "年级组长", type: 2 },
      //     { name: "班主任", type: 3 },
      //     { name: "教师", type: 4 },
      //     { name: "维修人员", type: 5 },
      //   ],
      teacherRoleVisible: false,
      teacherRoleForm: {
        roleId: "",
        teacherList: [],
        identity: "",
      },
      roleTypeList: [],
      allTeacherList: [],
      teacherName: "",
      isIndeterminate: true,
      checkAll: false,
      teacherIdList: [],
      identityList: [
        { name: "校内", value: 1 },
        { name: "校外", value: 2 },
      ],
    };
  },

  created() {
    this.loadRole();
    this.loadTeacherList();
  },
  methods: {
    loadRole() {
      const that = this;
      this.$post("/media/queryallrole.do", { roleName: "" })
        .then((res) => {
          this.roleTypeList = [];
          res.data.forEach((role) => {
            let roleObject = {};
            roleObject.name = role.name;
            roleObject.id = role.id;
            this.roleTypeList.push(roleObject);
          });
          that.tableData = res.data;
        })
        .catch((err) => {
          that.$message.error(err.message);
        });
    },
    authorityManage(id, name) {
      this.$router.push({ name: "authorityManage", query: { id, name } });
    },
    modifyRole(role) {
      this.addRoleVisible = true;
      this.$nextTick(() => {
        this.roleForm.roleId = role.id;
        this.roleForm.roleName = role.name;
        this.roleForm.roleType = { name: role.typeStr, type: role.type };
      });
    },
    deleteRole(id) {
      const that = this;
      this.$confirm("是否执行此操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/deleterole.do", { id })
            .then((res) => {
              that.$message({
                type: "success",
                message: "操作成功!",
              });
              that.loadRole();
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addRole() {
      this.addRoleVisible = true;
      this.$nextTick(() => {
        this.$refs["roleForm"].resetFields();
        this.roleForm.roleId = "";
      });
    },
    addRoleConfirm() {
      const that = this;
      this.$refs["roleForm"].validate((valid) => {
        if (valid) {
          let data = {
            name: this.roleForm.roleName,
            // type: this.roleForm.roleType.type,
          };
          let apiUrl = "/media/insertrole.do";
          if (this.roleForm.roleId) {
            data.id = this.roleForm.roleId;
            apiUrl = "/media/updaterole.do";
          }
          this.$confirm("是否执行此操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  that.addRoleVisible = false;
                  that.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  that.loadRole();
                })
                .catch((err) => {
                  that.$message.error(err.message);
                  that.addRoleVisible = false;
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
        }
      });
    },
    moduleManagement(id, name) {
      this.$router.push({ name: "moduleManagement", query: { id, name } });
    },
    // 教师授权
    addTeacherRoleConfirm() {
      if (
        this.teacherRoleForm.roleId === "" ||
        this.teacherRoleForm.teacherList.length === 0
      ) {
        this.$message({
          type: "warning",
          message: "请选择角色和教师",
        });
        return;
      }
      this.$confirm("是否执行此操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/media/updateManagerRole.do", {
            role_id: this.teacherRoleForm.roleId,
            managerStr: this.teacherRoleForm.teacherList.join(),
          })
            .then((res) => {
              this.teacherRoleVisible = false;
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            })
            .catch((err) => {
              this.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    loadTeacherList() {
      const that = this;
      this.$post("/media/queryAllManager.do", {
        name: this.teacherName,
        is_no: this.teacherRoleForm.identity, //查询身份是否校内，1是校内
      })
        .then((res) => {
          this.teacherIdList = [];
          this.allTeacherList = res.data;
          res.data.forEach((teacher) => {
            this.teacherIdList.push(teacher.id);
          });
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 点击教师授权
    teacherRoleClick() {
      this.teacherRoleVisible = true;
      this.teacherRoleForm.roleId = "";
      this.teacherRoleForm.teacherList = [];
    },
    // 教师全选
    handleCheckAllChange(val) {
      this.teacherRoleForm.teacherList = val ? this.teacherIdList : [];
      this.isIndeterminate = false;
    },
    teacherChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allTeacherList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.allTeacherList.length;
    },
    selectTeacher(event) {
      if (this.teacherRoleForm.teacherList.length === 0) {
        this.teacherRoleForm.teacherList.push(event);
      } else {
        this.teacherRoleForm.teacherList = event;
      }
    },
    // 选择身份查询教师
    identityChange() {
      this.loadTeacherList();
      this.teacherRoleForm.teacherList = [];
    },
  },
};
</script>

<style scoped lang="scss">
.teacherRoleForm {
  height: 500px;
  overflow-x: auto;
}
</style>
